<template>
  <svg
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.9875 8.96797L26.4625 17.3305C26.3367 18.0213 25.9726 18.6461 25.4335 19.096C24.8945 19.546 24.2147 19.7926 23.5125 19.793H9.0125L9.5625 22.793H23C23.5933 22.793 24.1734 22.9689 24.6667 23.2986C25.1601 23.6282 25.5446 24.0967 25.7716 24.6449C25.9987 25.1931 26.0581 25.7963 25.9424 26.3782C25.8266 26.9602 25.5409 27.4947 25.1213 27.9143C24.7018 28.3338 24.1672 28.6196 23.5853 28.7353C23.0033 28.8511 22.4001 28.7917 21.8519 28.5646C21.3038 28.3375 20.8352 27.953 20.5056 27.4597C20.1759 26.9663 20 26.3863 20 25.793C20.0001 25.452 20.0593 25.1137 20.175 24.793H12.825C12.9407 25.1137 12.9999 25.452 13 25.793C13.0009 26.277 12.8847 26.7541 12.6612 27.1834C12.4378 27.6128 12.1138 27.9817 11.7169 28.2587C11.3199 28.5357 10.8619 28.7126 10.3818 28.7742C9.90167 28.8358 9.4138 28.7803 8.9598 28.6125C8.5058 28.4446 8.09915 28.1694 7.77456 27.8104C7.44996 27.4513 7.21707 27.019 7.09576 26.5505C6.97444 26.0819 6.96831 25.5909 7.07788 25.1194C7.18746 24.648 7.40948 24.21 7.725 23.843L4.2625 4.79297H2C1.73478 4.79297 1.48043 4.68761 1.29289 4.50008C1.10536 4.31254 1 4.05819 1 3.79297C1 3.52775 1.10536 3.2734 1.29289 3.08586C1.48043 2.89833 1.73478 2.79297 2 2.79297H4.2625C4.7293 2.79399 5.18104 2.95826 5.53946 3.25732C5.89787 3.55639 6.14039 3.9714 6.225 4.43047L6.8375 7.79297H27C27.146 7.79327 27.2901 7.82594 27.4219 7.88862C27.5538 7.9513 27.6701 8.04244 27.7625 8.15547C27.8592 8.26495 27.9297 8.395 27.9687 8.53577C28.0077 8.67654 28.0141 8.82434 27.9875 8.96797Z"
      fill="white"
    />
  </svg>
</template>
