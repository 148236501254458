<script setup>
import UserCircleIcon from "../../../../components/icons/UserCircleIcon.vue";
import router from "../../../../router";
import { ref, onMounted, computed, watchEffect, watch } from "vue";
// import axios from "@/gateway/backendapi";
import axios from "../../public/baseurl/backendbaseurl";
import { useRoute } from "vue-router";
// import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import store from "../../../../store/store";

const categoryItem = ref([]);
const searchProduct = ref("");
const route = useRoute();
// const store = useStore();
const isLoggedIn = ref(false);
// const isSignUpIn = ref(false);
const loading = ref(false);
const cartCount = ref([]);
const searchedProduct = ref([]);
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const loggedIn = computed(() => store.getters["ecommerce/isLoggedIn"]);
console.log(loggedIn.value, "hhagg");

const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
// const cartCount2 = ref(store.getters["ecommerce/cartCount"])

const menuOpen = ref(false);

// Function to toggle the menu
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};
const setSelectedProduct = (item) => {
  console.log(item, 'lejek');
  router.push(`/store/bookdetails?productId=${item.id}`);
};

const cartCount2 = computed(() => {
  if (store.getters["ecommerce/cartCount"] == 0) {
    return cartCount.value;
  } else {
    return store.getters["ecommerce/cartCount"];
  }
});
// const isLoggedIn = computed(() => store.getters["ecommerceAuth/isLoggedIn"]);

const searchForProduct = async () => {
  loading.value = true;
  if (searchProduct.value.length >= 3) {
    try {
      const { data } = await axios.get(
        `/Ecommerce/SearchProducts?searchText=${searchProduct.value}&tenantId=${
          tenantId.value ? tenantId.value : sessionTennantId.value
        }`
      );
      searchedProduct.value = data;
      console.log(searchedProduct.value, "product");
      loading.value = false;
    } catch (error) {
      console.log(error);
      loading.value = false;
    }
  }
};

onMounted(() => {
  isLoggedIn.value = localStorage.getItem("loggedIn"); // Check if the login flag exists
  // isSignUpIn.value = !!localStorage.getItem("signedUp");
  if (isLoggedIn.value) {
    store.dispatch("ecommerce/setLogin", true); // Check if the login flag exists
  } else {
    store.dispatch("ecommerce/setLogin", false); // Check if the login flag exists
  }
  // const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  store.dispatch("ecommerce/setCartCount", cartCount.value);
  cartCount.value = JSON.parse(localStorage.getItem("cartItems"));
});
watchEffect(() => {
  console.log(cartCount2.value, "jjjjj");
  isLoggedIn.value = localStorage.getItem("loggedIn");
  const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  cartCount.value = storedCartItems;
  console.log(cartCount.value, "Cart items updated");
});

watch(
  () => isLoggedIn.value,
  (newVal) => {
    console.log("Login state changed:", newVal);
  }
);

const createAccount = () => {
  // Handle create account action
  console.log("Create account button clicked");
  router.push(
    `/store/createaccount?id=${tenantId.value ? tenantId.value : sessionTennantId.value}`
  );
};
const getAllProductInfo = async () => {
  try {
    const { data } = await axios.get(`/Ecommerce/GetStoreInformation/${route.query.id}`);
    console.log(data, "getAllProductInfo");
    categoryItem.value = data.categories;
  } catch (error) {
    console.log(error);
  }
};
getAllProductInfo();
const logOut = () => {
  store.dispatch("ecommerce/setLogin", false);
  localStorage.removeItem("loggedIn"); // Remove login status from local storage
  localStorage.removeItem("signedUp"); // Remove login status from local storage
  // localStorage.removeItem('cartItems'); // Clear cart items (optional)
  isLoggedIn.value = false; // Update state
  // router.push('/store/login'); // Redirect to login page
  ElMessage({
    type: "success",
    showClose: true,
    message: "Logout Successfully",
    duration: 10000,
  });
};
</script>

<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
      <div class="container-fluid justify-content-center">
        <div class="row w-100 justify-content-between align-items-center">
          <div class="col-md-3 col-12 align-item-center justify-content-between d-flex">
            <a class="navbar-brand" href="#">
              <img
                src="../../../../assets/churchplusblueLogo.png"
                alt="Churchplus Logo"
                class="logo"
              />
            </a>
            <div class=" bar-position">
            <div class="hidden-md-and-up pa-3 menu-icon toggle" @click="toggleMenu">
              <i class="pi pi-bars"></i>
            </div>
          </div>
          </div>

          <!-- Collapsing content -->
          <div
            class="col-md-5 col-12"
            :class="{ menuopen: menuOpen, menuclose: !menuOpen }"
          >
            <!-- Use v-if or v-show to toggle menu visibility -->
            <div class="col-md-12">
              <!-- <el-input
                v-model="searchProduct"
                placeholder="Search items product & services"
                class="w-100 adjustradius"
              >
                <template #suffix>
                  <div>
                    <el-button size="large" color="#0A0909" class="py-3 text-white w-100"
                      ><el-icon><Search /></el-icon
                    ></el-button>
                  </div>
                </template>
              </el-input> -->
              <div class="dropdown w-100">
                <el-dropdown trigger="click" class="w-100">
                  <span class="el-dropdown-link w-100">
                    <el-input
                      v-model="searchProduct"
                      @input="searchForProduct"
                      type="text"
                      placeholder="Search items product & services"
                      class="w-100 adjustradius"
                    >
                      <template #suffix>
                        <div>
                          <el-button
                            size="large"
                            color="#0A0909"
                            class="py-3 text-white w-100"
                            ><el-icon><Search /></el-icon
                          ></el-button>
                        </div>
                      </template>
                    </el-input>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        v-for="item in searchedProduct"
                        :key="item.id"
                        @click="setSelectedProduct(item)"
                      >
                        {{ item ? item.name : "" }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </div>
          </div>

          <!-- Account-related actions -->
          <div class="d-flex col-md-3 justify-content-end align-items-center">
            <div v-if="!loggedIn" class="nav-item">
              <el-button
                round
                size="large"
                color="#FF5906"
                class="text-white mr-3"
                @click="createAccount"
              >
                Create account
              </el-button>
            </div>
            <div class="nav-item" v-if="loggedIn">
              <div
                @click="logOut"
                class="nav-link cursor-pointer text-decoration-none text-dak d-flex align-items-center"
              >
                <span>LogOut</span>
                <span><UserCircleIcon  class="w-14 h-14" /></span>
              </div>
            </div>
            <div v-if="!loggedIn" class="nav-item">
              <router-link
                to="/store/login"
                class="nav-link text-decoration-none text-dak d-flex align-items-center"
              >
                <span>Login</span>
                <span><UserCircleIcon  class="w-14 h-14" /></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Login Form -->
  </div>
</template>

<style scoped>
.logo {
  width: 8rem;
  height: auto;
}

.navbar {
  padding: 1rem 0;
}
.menu-icon {
  /* border-right: 1px solid #BCCCDD; */
  background: #ffffff;
}
/* .toggle {
  display: none;
} */
.navbar-collaps {
  display: block;
}

.btn-custom {
  background-color: #000;
  color: #fff;
  margin-right: 1rem; /* Add margin to the right */
}

.navbar-nav .nav-link {
  font-size: 1rem;
  font-weight: 500;
}
.bar-position{
  position: relative;
  top: 10px;
}
.pa-3 {
  padding: 1.1rem;
}
.el-input .el-input__wrapper {
  border-radius: 20px !important;
}

.navbar-nav .nav-link.active {
  font-weight: 700;
}

.navbar-nav .dropdown-menu {
  border-radius: 0.25rem;
}

@media screen and (max-width: 991px) {
  .toggle {
    display: block;
  }
  .menuopen {
    display: block;
  }
  .menuclose {
    display: none;
  }
  .navbar-collaps {
    display: none !important;
  }

  .links-menu {
    z-index: 999;
    position: fixed;
    background: white;
    left: -266px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .links-menu.show {
    position: fixed;
    left: 0;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .main-con {
    width: 100%;
  }
}
</style>
