<!-- HeaderSection.vue -->
<template>
  <header class="header col-md-12 bg-dark text-white py-3">
    <div class="row justify-content-center">
      <div class="col-md-3">
        <div
          v-if="
            !route.fullPath.includes(
              `/store/home?id=${tenantId ? tenantId : sessionTennantId}`
            )
          "
          class="d-flex col-md-12 align-items-center cursor-pointer mt-1 justify-content-center mr-2 text-white"
          @click="goBack"
        >
          <el-icon :size="25"><Back /></el-icon>
          <span class="ml-1">Back</span>
        </div>
      </div>
      <div class="d-none d-md-block col-md-7">
        <div class="row justify-content-center">
          <div class="col-md-7 d-flex align-items-center justify-content-between">
            <div>
              <router-link
                :to="`/store/home?id=${tenantId ? tenantId : sessionTennantId}`"
                class="nav-link active text-white"
                >Home</router-link
              >
            </div>
            <div>
              <el-dropdown>
                <span class="el-dropdown-link text-white mt-1">
                  {{
                    selectedCategory && selectedCategory.categoryName
                      ? selectedCategory.categoryName
                      : "Category"
                  }}
                  <el-icon class="el-icon--right">
                    <arrow-down />
                  </el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="(item, index) in categoryItem"
                      :key="index"
                      @click="setCategory(item)"
                      class="cursor-pointer"
                      >{{ item.categoryName }}</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
            <div>24/7Support</div>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-12 text-center mt-1">
        Shopping cart<router-link to="/store/cart" class="" href="#">
          <span class="ml-2"><ShoppingCartIcon class="w-11 h-11" /></span>
          <span class="badge badge-pill text-white" style="background: #ff4f19">{{
            cartCount.length
          }}</span>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect } from "vue";
import axios from "@/gateway/backendapi";
import { useRoute } from "vue-router";
import store from "../../../../store/store";
import ShoppingCartIcon from "../../../../components/icons/ShoppingCartIcon.vue";

const route = useRoute();
const selectedCategory = ref({});
const categoryItem = ref([]);
const isLoggedIn = ref(false);
const isSignUpIn = ref(false);
const cartCount = ref([]);
const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const goBack = () => {
  // Logic to navigate back
  window.history.back();
};
const setCategory = (item) => {
  selectedCategory.value = item;
};
const cartCount2 = computed(() => {
  if (store.getters["ecommerce/cartCount"] == 0) {
    return cartCount.value;
  } else {
    return store.getters["ecommerce/cartCount"];
  }
});
onMounted(() => {
  isLoggedIn.value = localStorage.getItem("loggedIn"); // Check if the login flag exists
  isSignUpIn.value = !!localStorage.getItem("signedUp");
  if (isLoggedIn.value) {
    store.dispatch("ecommerce/setLogin", true); // Check if the login flag exists
  } else {
    store.dispatch("ecommerce/setLogin", false); // Check if the login flag exists
  }
  // const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  store.dispatch("ecommerce/setCartCount", cartCount.value);
  cartCount.value = JSON.parse(localStorage.getItem("cartItems"));
});
watchEffect(() => {
  console.log(cartCount2.value, "jjjjj");
  isLoggedIn.value = localStorage.getItem("loggedIn");
  const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  cartCount.value = storedCartItems;
  console.log(cartCount.value, "Cart items updated");
});
const getAllProductInfo = async () => {
  try {
    const { data } = await axios.get(`/Ecommerce/GetStoreInformation/${route.query.id}`);
    console.log(data, "getAllProductInfo");
    categoryItem.value = data.categories;
  } catch (error) {
    console.log(error);
  }
};
getAllProductInfo();
</script>

<style scoped>
.header {
  background-color: #12091c !important; /* Adjust background color as needed */
  color: white; /* Adjust text color as needed */
}
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>
