<template>
  <div>
    <svg
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.61 32.3996C30.4104 32.3996 34.3019 28.5081 34.3019 23.7076C34.3019 18.9072 30.4104 15.0156 25.61 15.0156C20.8095 15.0156 16.918 18.9072 16.918 23.7076C16.918 28.5081 20.8095 32.3996 25.61 32.3996Z"
        fill="black"
        fill-opacity="0.5"
      />
      <path
        d="M25.6111 4.74219C21.5477 4.74219 17.5756 5.94711 14.1971 8.20459C10.8185 10.4621 8.18527 13.6707 6.63029 17.4248C5.07531 21.1788 4.66845 25.3097 5.46118 29.2949C6.2539 33.2802 8.21059 36.9409 11.0838 39.8142C13.957 42.6874 17.6178 44.6441 21.603 45.4368C25.5883 46.2295 29.7192 45.8227 33.4732 44.2677C37.2273 42.7127 40.4359 40.0794 42.6934 36.7009C44.9509 33.3223 46.1558 29.3502 46.1558 25.2869C46.1453 19.8413 43.9775 14.6217 40.1269 10.7711C36.2762 6.92051 31.0567 4.75263 25.6111 4.74219ZM38.6096 36.8235C37.3303 34.9885 35.6818 33.4409 33.7697 32.28C31.5743 34.3832 28.6514 35.5574 25.6111 35.5574C22.5708 35.5574 19.6479 34.3832 17.4525 32.28C15.5404 33.4409 13.8919 34.9885 12.6126 36.8235C10.3878 34.3182 8.93444 31.2233 8.42737 27.9113C7.92029 24.5994 8.38114 21.2114 9.75446 18.1552C11.1278 15.099 13.355 12.5048 16.1682 10.6848C18.9813 8.86476 22.2605 7.89648 25.6111 7.89648C28.9617 7.89648 32.2409 8.86476 35.054 10.6848C37.8672 12.5048 40.0944 15.099 41.4677 18.1552C42.8411 21.2114 43.3019 24.5994 42.7948 27.9113C42.2878 31.2233 40.8344 34.3182 38.6096 36.8235Z"
        fill="black"
        fill-opacity="0.5"
      />
    </svg>
  </div>
</template>
